<style lang="scss">
#view-proposal-invoice{
  .top-bar{
      @apply rounded;
      @apply mb-8;
      border:1px solid #D1D1D1;

      div{
          @apply px-4;
          @apply py-2;
          border:none;
      }

      .border{
          border-left:1px solid #D1D1D1;
      }

      .active{
          @apply bg-blue;
          color:#fff;
      }
  }

  .heading{
    background: #F9F9F9;
    color: #000;
    line-height: 58px;
    border-bottom: 1px solid;
    border-color: #ededed;
    margin-bottom: 10px;
    font-weight:normal;
  }

  .pdf-loader{
    border-top:5px solid #0942BC;
    width:0%;
    top:0;
    left:0;
    position:fixed;
  }
}

.proposal{
    &.proposal-print{
      margin-left:-12px!important;
    }
  }
</style>
<template>
  <div
    id="view-proposal-invoice"
    class="bg-grey-light pt-12"
  >
    <vue-headful
      :title="`${metaTitle} | Octoa`"
    />

    <v-inner-header
      v-if="viewType == 'edit'"
      type="proposal"
      mode="view"
      :item="proposal"
      :redirect="`/projects/${proposal.project_id}`"
      :template="false"
      @edit="edit"
      @share="copyShareLink"
      @deleteItem="deleteItem"
      @markAsSent="markAsSent"
      @markAsUnsent="markAsUnsent"
    />

    <div
      v-if="isPdfLoading"
      class="pdf-loader"
      :style="{ width: pdfLoaderProgress + '%' }"
    />

    <modal
      name="successAccepted"
      class="modal"
      width="100%"
      :max-width="300"
      height="auto"
      :adaptive="true"
    >
      <div class="text-center">
        <img
          src="@/assets/img/icons/checkmark.svg"
          width="50"
          class="text-center"
        >
      </div>
      <div class="text-center mt-5 text-lg font-bold">
        {{ $translate('done', locale) }}
      </div>
      <p class="text-center mt-2">
        {{ company.name }} {{ $translate('companyNotify', locale) }}
      </p>
    </modal>

    <div
      v-if="!loading && !error"
      class="print-hidden  max-w-xl mx-4 lg:mx-auto text-right "
      :class="{ 'pt-10' : viewType == 'edit'}"
    >
      <div
        v-if="!isPdfLoading"
        class="flex items-center cursor-pointer justify-end text-green mb-5"
      >
        <div>
          <img
            src="@/assets/img/icons/ico-save-green.svg"
            width="18"
            class="mr-2"
          >
        </div>
        <div
          class="underline mb-1"
          @click="savePdf"
        >
          <span class="hidden md:inline">{{ $translate('saveAsPdf', locale) }}</span>
          <span class="inline md:hidden">{{ $translate('openAsPdf', locale) }}</span>
        </div>
      </div>
      <div
        v-if="isPdfLoading"
        class="text-right mb-2 mr-1"
      >
        <img
          src="@/assets/img/icons/loader.svg"
          width="35"
        >
      </div>
    </div>

    <div class="z-0">
      <div
        v-if="loading"
        class="w-full px-2 h-screen text-center"
      >
        <img
          src="@/assets/img/icons/loader.svg"
          width="50"
          class="mt-32 text-center"
        >
      </div>
      <div v-else>
        <div
          v-if="error"
          class="text-center h-screen md:-mt-12 pb-32 md:pb-0 flex items-center flex-wrap max-w-xl mx-auto"
        >
          <div class="w-full mt-24 md:-mt-10">
            <router-link to="/">
              <img
                src="@/assets/img/octoa.png"
                width="60"
                class="pb-8"
              >
            </router-link>
            <br><br>
            <div class="mx-4 md:-mx-4 flex flex-wrap text-left items-center">
              <div class="w-full md:w-1/2 px-4">
                <h1 class="text-3xl md:text-5xl">
                  Sorry, this proposal isn’t here.
                </h1>
                <h2 class="text-xl mt-3 md:mt-0 md:text-3xl">
                  You didn’t do anything wrong.
                </h2>
              </div>
              <div class="w-full mt-8 md:mt-0 md:w-1/2 text-base px-4">
                <div class="font-bold text-lg">
                  Did you follow a link from someone?
                </div>
                <div class="mt-2">
                  If you received this link from someone, this proposal may already have been removed or the link has changed.
                </div>

                <div class="mt-5 font-bold text-lg">
                  Did you follow a link from here?
                </div>
                <div class="mt-2">
                  If you reached this page from another part of octoa.com, please let us know so we can correct our mistake.
                </div>

                <div class="mt-5 font-bold text-lg">
                  Did you type the URL?
                </div>
                <div class="mt-2">
                  You may have typed the address (URL) incorrectly. Check to make sure you’ve got the exact right spelling, capitalization, etc.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="mb-10 pb-10 pt-8 proposal-form bg-white rounded shadow max-w-xl mx-4 lg:mx-auto"
        >
          <div class="proposal flex flex-wrap max-w-xl mx-auto items-center ">
            <div class="w-full mx-auto">
              <div class="flex flex-wrap px-8">
                <div class="w-full md:w-3/5 print-50">
                  <div
                    v-if="logo.path"
                    class="logo"
                  >
                    <img
                      :src="logo.path"
                      width="100%"
                      :style="[logo.width > logo.height ? {'max-width' : '278px'} : '', logo.width < logo.height ? { 'width' : 'auto', 'max-height':'250px'} : '', logo.width == logo.height ? { 'width' : '88px', 'height':'88px'} : '' ]"
                    >
                  </div>
                  <div class="flex flex-wrap items-center ">
                    <div class="w-full md:w-2/5 lg:w-4/12  text-grey-semi-light">
                      {{ $translate('quote', locale) }}
                    </div>
                    <div class="mt-3 md:mt-0 font-bold w-full md:w-3/5 lg:w-8/12 ">
                      {{ numberPrefix }}{{ referenceNumber }}
                    </div>
                  </div>

                  <div class="my-5 flex-wrap flex items-center ">
                    <div class="w-full md:w-2/5 lg:w-4/12  text-grey-semi-light">
                      {{ $translate('issued', locale) }}
                    </div>
                    <div class="mt-3 md:mt-0 w-full md:w-3/5 lg:w-8/12 ">
                      {{ issuedAt | viewDate(locale) }}
                    </div>
                  </div>

                  <div class="flex flex-wrap items-center ">
                    <div class="w-full md:w-2/5 lg:w-4/12  text-grey-semi-light">
                      {{ $translate('dueDate', locale) }}
                    </div>
                    <div class="mt-3 md:mt-0 w-full md:w-3/5 lg:w-8/12 ">
                      {{ validUntil | viewDate(locale) }}
                    </div>
                  </div>
                  <div
                    v-show="subject != null"
                    class="flex flex-wrap items-start mt-5"
                  >
                    <div class="w-full md:w-2/5 lg:w-4/12  text-grey-semi-light">
                      {{ $translate('subject', locale) }}
                    </div>
                    <div class="mt-3 md:mt-0 w-full md:w-3/5 lg:w-8/12 ">
                      {{ subject }}
                    </div>
                  </div>
                </div>
                <div class="w-full md:w-2/5 md:text-right text-sm mt-5 md:mt-0 print-50">
                  <div class="flex">
                    <div class="w-1/3 md:w-1/2 md:text-right">
                      <div class="mr-12 text-grey-semi-light leading-loosen">
                        {{ $translate('from', locale) }}
                      </div>
                    </div>
                    <div
                      v-if="user"
                      class="w-2/3 md:w-1/2 text-left text-grey-darker leading-loosen break-words"
                    >
                      <strong class="text-black">{{ company.name }}</strong> <br>
                      <pre style="font-family:inherit">{{ company.address }}</pre>
                      <div v-if='locale == "fi" || locale == "de"'>
                        <span v-if="company.postal_code">{{ company.postal_code }} </span>
                        <span
                          v-if="company.city"
                          style="font-family:inherit"
                        >{{ company.city }}</span>
                      </div>
                      <div v-else>
                        <pre
                          v-if="company.city"
                          style="font-family:inherit"
                        >{{ company.city }}<span v-if="company.postal_code">,</span> {{ company.postal_code }}</pre>
                      </div>
                      {{ company.country }}
                    </div>
                  </div>
                  <div
                    v-if="paymentInfo"
                    class="flex flex-wrap"
                  >
                    <div class="w-1/3 md:w-1/2 md:text-right" />
                    <div class="w-2/3 md:w-1/2 text-left text-grey-darker leading-loosen break-words">
                      <pre>{{ paymentInfo }}</pre>
                    </div>
                  </div>
                  <div class="flex mt-4 md:mt-5">
                    <div class="w-1/3 md:w-1/2 md:text-right">
                      <div class="mr-12 text-grey-semi-light leading-loosen break-words">
                        {{ $translate('to', locale) }}
                      </div>
                    </div>
                    <div class="w-2/3 md:w-1/2 text-left">
                      <div
                        v-if="contact"
                        class="text-grey-darker leading-loosen"
                      >
                        <strong v-if="contact.company_name"> {{ contact.company_name }}<br></strong>
                        <div v-if="contact.same_as_billing_address">
                          {{ contact.full_name }}<br>
                          <pre style="font-family:inherit">{{ contact.address }}</pre>
                          <pre
                            v-if="contact.city"
                            style="font-family:inherit"
                          >{{ contact.city }}<span v-if="contact.postal_code">,</span> {{ contact.postal_code }}</pre>
                          {{ contact.country }} <br>
                        </div>
                        <div v-if="!contact.same_as_billing_address">
                          {{ contact.billing_first_name }} {{ contact.billing_last_name }}<br>
                          <pre style="font-family:inherit">{{ contact.billing_address }}</pre>
                          <pre
                            v-if="contact.billing_city"
                            style="font-family:inherit"
                          >{{ contact.billing_city }}<span v-if="contact.billing_postal_code">,</span> {{ contact.billing_postal_code }}</pre>
                          {{ contact.billing_country }} <br>
                        </div>
                        <span v-if="contact.vat">{{ contact.vat }} <br></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <h3 class="mt-10 mb-3 w-full px-8">
                {{ $translate('deliverables', locale) }}
              </h3> -->
              <div class="mt-10 px-4 heading">
                <div class="flex w-full">
                  <div class="text-grey-darker flex px-2 w-full">
                    <div class="w-1/2 px-2 print-heading-left">
                      {{ $translate('item', locale) }}
                    </div>
                    <div class="hidden w-1/2 px-2 md:flex justify-end print-heading-right">
                      <div class="w-1/6 pr-2 text-center">
                        {{ $translate('quantity', locale) }}
                      </div>
                      <div
                        v-if="isTaxEnable"
                        class="w-1/6 px-2 text-center"
                      >
                        {{ $translate('tax', locale) }}
                      </div>
                      <div class="w-2/6 px-2 text-right">
                        {{ $translate('price', locale) }}
                      </div>
                      <div class="w-2/6 text-right">
                        {{ $translate('total', locale) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <!-- Required list items -->
              <div
                v-for="item in listofItems"
                :key="item.id"
                class="item w-full flex px-8 py-3 flex-wrap "
              >
                <div class="md:border-b pb-5 border-grey-lighter w-full md:w-1/2 print-item-name items-center">
                  <div class="font-bold text-base break-words">
                    {{ item.name }}
                  </div>
                  <div
                    v-if="item.description"
                    class="pt-3 text-grey-darker"
                  >
                    <pre>{{ item.description }}</pre>
                  </div>
                </div>

                <div class="border-bottom pb-5 hidden pl-2 w-1/2 md:flex justify-end print-item-detail items-center">
                  <div class="w-1/6 text-center px-2">
                    <div v-if="!item.is_quantity_selectable">
                      {{ item.quantity | showHideDecimal(currency.decimal_separator) }}
                    </div>
                    <input
                      v-else
                      v-model="item.quantity"
                      type="number"
                      min="0"
                      class="quantity"
                      name="quantity"
                      @input="checkQuantity(item, $event)"
                    >
                  </div>
                  <div
                    v-if="isTaxEnable"
                    class="w-1/6 text-center px-4"
                  >
                    {{ item.tax }}%
                  </div>
                  <div class="w-2/6 px-2 text-right">
                    <vue-numeric
                      :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                      :precision="2"
                      read-only
                      :currency="currency.sign"
                      :minus="false"
                      :decimal-separator="currency.decimal_separator"
                      :thousand-separator="currency.thousand_separator"
                      :min="0"
                      :value="item.price"
                    />
                  </div>
                  <div class="w-2/6">
                    <div class="text-right break-words">
                      <vue-numeric
                        :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                        :precision="2"
                        read-only
                        read-only-class="font-bold"
                        :currency="currency.sign"
                        :minus="false"
                        :decimal-separator="currency.decimal_separator"
                        :thousand-separator="currency.thousand_separator"
                        :min="0"
                        :value="calculateUnitPrice(item)"
                      />
                    </div>
                  </div>
                </div>

                <div class="flex md:hidden justify-between w-full pb-5 leading-loosen border-bottom print-hidden ">
                  <div>
                    <strong>{{ $translate('quantity', locale) }}</strong> <br>

                    <div v-if="!item.is_quantity_selectable">
                      {{ item.quantity | showHideDecimal(currency.decimal_separator) }}
                    </div>
                    <input
                      v-else
                      v-model="item.quantity"
                      type="number"
                      min="0"
                      step="1"
                      class="quantity mt-1"
                      name="quantity"
                      @input="checkQuantity(item, $event)"
                    >
                  </div>
                  <div v-if="isTaxEnable">
                    <strong>{{ $translate('tax', locale) }}</strong><br> {{ item.tax }}%
                  </div>
                  <div>
                    <strong>{{ $translate('price', locale) }}</strong><br> <vue-numeric
                      :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                      :precision="2"
                      read-only
                      :currency="currency.sign"
                      :minus="false"
                      :decimal-separator="currency.decimal_separator"
                      :thousand-separator="currency.thousand_separator"
                      :min="0"
                      :value="item.price"
                    />
                  </div>
                  <div>
                    <strong>{{ $translate('total', locale) }}</strong><br> <vue-numeric
                      :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                      :precision="2"
                      read-only
                      :currency="currency.sign"
                      :minus="false"
                      :decimal-separator="currency.decimal_separator"
                      :thousand-separator="currency.thousand_separator"
                      :min="0"
                      :value="calculateUnitPrice(item)"
                    />
                  </div>
                </div>
              </div>

              <!-- Additional options -->
              <div
                v-if="listofAdditonalItems && listofAdditonalItems.length > 0"
                class="px-8"
              >
                <div class="flex flex-wrap">
                  <h3 class="mb-3 mt-10 w-full">
                    {{ $translate('optionalItems', locale) }}
                  </h3>
                </div>
              </div>

              <div
                v-if="listofAdditonalItems && listofAdditonalItems.length > 0"
                class="px-4 heading"
              >
                <div class="text-grey-darker flex px-2 w-full">
                  <div class="w-1/2 px-2">
                    {{ $translate('item', locale) }}
                  </div>
                  <div class="w-1/2 px-2 hidden md:flex justify-end">
                    <div class="w-1/6 pr-2 text-center">
                      {{ $translate('quantity', locale) }}
                    </div>
                    <div
                      v-if="isTaxEnable"
                      class="w-1/6 px-2 text-center"
                    >
                      {{ $translate('tax', locale) }}
                    </div>
                    <div class="w-2/6 px-2 text-right">
                      {{ $translate('price', locale) }}
                    </div>
                    <div class="w-2/6 text-right">
                      {{ $translate('total', locale) }}
                    </div>
                  </div>
                </div>
              </div>


              <!-- Additional options list -->
              <div
                v-for="(item, index) in listofAdditonalItems"
                :key="index"
                class="item w-full flex px-8 py-3 flex-wrap "
              >
                <div class="w-full pb-5 md:w-1/2 text-sm md:border-b border-grey-lighter print-item-name flex">
                  <v-checkbox
                    :id="`item${index}-isSelected`"
                    v-model="item.is_selected"
                    classes="small"
                    label=""
                    :checked="item.is_selected"
                    :active="(proposal.accepted_at) ? true : false "
                  />
                  <div class="px-3">
                    <div class="font-bold text-base break-words">
                      {{ item.name }}
                    </div>
                    <div
                      v-if="item.description"
                      class="pt-3 text-grey-darker"
                    >
                      <pre>{{ item.description }}</pre>
                    </div>
                  </div>
                </div>

                <div class="border-bottom pb-5  hidden pl-2 w-1/2 md:flex print-item-detail justify-end print-flex items-center">
                  <div class="w-1/6 px-2 text-center">
                    <div v-if="!item.is_quantity_selectable">
                      {{ item.quantity | showHideDecimal(currency.decimal_separator) }}
                    </div>
                    <input
                      v-else
                      v-model="item.quantity"
                      type="number"
                      min="0"
                      step="1"
                      class="quantity"
                      name="quantity"
                      @input="checkQuantity(item, $event)"
                    >
                  </div>
                  <div
                    v-if="isTaxEnable"
                    class="w-1/6 px-2 text-center"
                  >
                    {{ item.tax }}%
                  </div>
                  <div class="w-2/6 px-2 text-right">
                    <vue-numeric
                      :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                      :precision="2"
                      read-only
                      :currency="currency.sign"
                      :minus="false"
                      :decimal-separator="currency.decimal_separator"
                      :thousand-separator="currency.thousand_separator"
                      :min="0"
                      :value="item.price"
                    />
                  </div>
                  <div class="w-2/6 flex">
                    <div class="w-full text-right break-words">
                      <vue-numeric
                        :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                        :precision="2"
                        read-only
                        read-only-class="font-bold"
                        :currency="currency.sign"
                        :minus="false"
                        :decimal-separator="currency.decimal_separator"
                        :thousand-separator="currency.thousand_separator"
                        :min="0"
                        :value="calculateUnitPrice(item)"
                      />
                    </div>
                  </div>
                </div>

                <div class="flex md:hidden justify-between w-full pb-5 leading-loosen border-bottom print-hidden">
                  <div>
                    <strong>{{ $translate('quantity', locale) }}</strong> <br>
                    <div v-if="!item.is_quantity_selectable">
                      {{ item.quantity | showHideDecimal(currency.decimal_separator) }}
                    </div>
                    <input
                      v-else
                      v-model="item.quantity"
                      type="number"
                      min="0"
                      class="quantity mt-1"
                      name="quantity"
                      @input="checkQuantity(item, $event)"
                    >
                  </div>
                  <div v-if="isTaxEnable">
                    <strong>{{ $translate('tax', locale) }}</strong><br> {{ item.tax }}%
                  </div>
                  <div>
                    <strong>{{ $translate('price', locale) }}</strong><br> <vue-numeric
                      :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                      :precision="2"
                      read-only
                      :currency="currency.sign"
                      :minus="false"
                      :decimal-separator="currency.decimal_separator"
                      :thousand-separator="currency.thousand_separator"
                      :min="0"
                      :value="item.price"
                    />
                  </div>
                  <div>
                    <strong>{{ $translate('total', locale) }}</strong><br> <vue-numeric
                      :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                      :precision="2"
                      read-only
                      :currency="currency.sign"
                      :minus="false"
                      :decimal-separator="currency.decimal_separator"
                      :thousand-separator="currency.thousand_separator"
                      :min="0"
                      :value="calculateUnitPrice(item)"
                    />
                  </div>
                </div>
              </div>

              <!-- Sub total -->
              <div class="flex px-8 mt-3 print-footer">
                <div class="md:w-3/5 print-50" />
                <div class="w-full md:w-2/5 flex flex-wrap  print-footer-right">
                  <div
                    v-show="discountTotal > 0"
                    class="w-1/2"
                  >
                    {{ discount }}% {{ $translate('discount', locale) }}
                  </div>
                  <div
                    v-show="discountTotal > 0"
                    class="w-1/2 text-right"
                  >
                    -<vue-numeric
                      :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                      :precision="2"
                      read-only
                      :currency="currency.sign"
                      :decimal-separator="currency.decimal_separator"
                      :thousand-separator="currency.thousand_separator"
                      :min="0"
                      :value="discountTotal"
                    />
                  </div>
                  <hr
                    v-show="discountTotal > 0"
                    class="my-5"
                  >

                  <div class="w-1/2">
                    {{ $translate('subtotal', locale) }} <span v-if="isTaxEnable && isTaxPriceIncluded">({{ $translate('exclTax', locale) }})</span>
                  </div>
                  <div class="w-1/2 text-right">
                    <vue-numeric
                      :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                      :precision="2"
                      read-only
                      :currency="currency.sign"
                      :minus="false"
                      :decimal-separator="currency.decimal_separator"
                      :thousand-separator="currency.thousand_separator"
                      :min="0"
                      :value="viewSubTotal"
                    />
                  </div>
                  <div
                    v-for="(tax, key) in taxClasses"
                    v-if="isTaxEnable"
                    :key="key"
                    class="w-full flex flex-wrap mt-5"
                  >
                    <hr
                      v-if="isTaxEnable"
                      class="mb-4"
                    >
                    <div
                      v-if="isTaxEnable"
                      class="w-1/2"
                    >
                      {{ $translate('tax', locale) }} {{ key }}%
                    </div>
                    <div
                      v-if="isTaxEnable"
                      class="w-1/2 text-right"
                    >
                      <vue-numeric
                        :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                        :precision="2"
                        read-only
                        :currency="currency.sign"
                        :minus="false"
                        :decimal-separator="currency.decimal_separator"
                        :thousand-separator="currency.thousand_separator"
                        :min="0"
                        :value="tax"
                      />
                    </div>
                  </div>

                  <hr class="my-5 thick">
                  <div class="w-1/2 text-lg font-bold">
                    {{ $translate('total', locale) }} <span v-if="isTaxEnable && isTaxPriceIncluded">({{ $translate('inclTax', locale) }})</span> ({{ currency.code }})
                  </div>
                  <div class="w-1/2 text-lg font-bold text-right">
                    <vue-numeric
                      :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                      :precision="2"
                      read-only
                      :currency="currency.sign"
                      :minus="false"
                      :decimal-separator="currency.decimal_separator"
                      :thousand-separator="currency.thousand_separator"
                      :min="0"
                      :value="total"
                    />
                  </div>
                </div>
              </div>

              <div v-if="contract">
                <hr class="mt-5">

                <h3 class="mt-6 mb-3 w-full px-8">
                  {{ $translate('contact', locale) }}
                </h3>
                <div>
                  <div
                    class="contract-content"
                    v-html="contract.content"
                  />
                </div>
              </div>

              <div v-if="listofPaymentItems.length > 0">
                <hr class="mt-8">
                <div
                  v-if="proposal.has_payment_schedules"
                  class="pt-6"
                >
                  <div class="px-8">
                    <div class="flex flex-wrap">
                      <h3 class="mb-5 w-full">
                        {{ $translate('paymentTerms', locale) }}
                      </h3>
                      <p />
                    </div>
                  </div>

                  <div class="px-6">
                    <ul
                      v-for="(item, index) in listofPaymentItems"
                      :key="index"
                      class="mb-2 text-sm ml-10"
                    >
                      <li
                        v-if="item.type == 'on_receive'"
                        style="list-style: disc;"
                      >
                        {{ item.percentage }}% {{ $translate('proposalIsAccepted', locale) }}
                      </li>
                      <li
                        v-if="item.type == 'on_completed'"
                        style="list-style: disc;"
                      >
                        {{ item.percentage }}% {{ $translate('projectIsCompleted', locale) }}
                      </li>
                      <li
                        v-if="item.type != 'on_receive' && item.type != 'on_completed'"
                        style="list-style: disc;"
                      >
                        {{ item.percentage }}% {{ $translate('by', locale) }} {{ item.date | viewDate(locale) }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div v-show="notes">
                <hr class="mt-8">
                <div class="px-8 pt-6 pb-6 text-sm">
                  <h3 class="mb-5 w-full">
                    {{ $translate('additionalNotes', locale) }}
                  </h3>
                  <pre style="max-width: 100%; word-break: break-word;">{{ notes }}</pre>
                </div>
              </div>
            </div>
          </div>
          <div
            v-show="(viewType == 'token' && !proposal.accepted_at)"
            class="bg-blue-washed-light rounded mt-10 px-8 py-8 mx-8"
          >
            <h3 class="mb-3 w-full">
              {{ $translate('acceptProposal', locale) }}
            </h3>
            <p>{{ $translate('acceptTerms', locale) }}</p>
            <div class="mb-5 w-full max-w-xs ">
              <input
                v-model="signature"
                type="text"
                :placeholder="$translate('signHere', locale)"
                name="signature"
                class="signature"
              >
            </div>
            <div class="mt-5 flex flex-wrap items-center">
              <div class="w-full md:w-auto md:mr-5">
                <v-checkbox
                  id="accept"
                  v-model="accept"
                  label="Yes, I agree to and accept this quote."
                  :label="$translate('yesIagree', locale)"
                />
              </div>
              <div class="mt-5 w-full">
                <button
                  v-if="!saving"
                  class="green-btn checkmark"
                  @click="acceptProposal"
                >
                  {{ $translate('acceptProposal', locale) }}
                </button>
              </div>
            </div>
          </div>

          <div
            v-show="proposal.accepted_at"
            class="bg-blue-washed-light  rounded mt-5 px-8 py-8 mx-8"
          >
            <h3 class="mb-5 w-full  max-w-xs">
              {{ $translate('proposalAccepted', locale) }}
            </h3>
            <div
              v-if="signature"
              class="mb-5 w-full"
            >
              <input
                v-model="signature"
                type="text"
                name="signature"
                disabled
                class="signature"
              >
            </div>
            <p>
              {{ contact.full_name }} {{ $translate('acceptedOn', locale) }} {{ proposal.accepted_at | humanDate(timezone) }}.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!loading && viewType == 'token' && !error"
      class="header simple w-full text-center py-5 z-10 relative bg-white"
    >
      Powered by <a href="https://octoa.com">Octoa</a>
    </div>
  </div>
</template>
<script>

import config from '@/config'
import { logException } from '@/helpers'
import { addDays, format } from 'date-fns'
import { calculateEachTaxClassPriceWithAdditional, trim2Decimals, calculateDiscountTotal, calculateEachTaxClassPrice, calculateTaxTotal } from '@/utils/helpers'
import VueHtml2pdf from 'vue-html2pdf'

export default {
  components: {
    VueHtml2pdf
  },
  data(){
    return {
      loading: true,
      isPdfLoading: false,
      pdfLoaderProgress: 0,
      locale: 'en',
      saving: false,
      metaTitle: 'Loading',
      currency:{
        sign: '€'
      },
      logo: {path:'', width:0, height:0 },
      viewType: 'token',
      currentView: 1,
      paymentInfo: '',
      project: null,
      proposal: {},
      contact: {},
      numberPrefix: null,
      issuedAt: null,
      validUntil: null,
      referenceNumber:'',
      subject: null,
      contract: null,
      id: this.$route.params.proposalId,
      token: this.$route.params.token,
      isTaxEnable: false,
      isTaxPriceIncluded: false,
      taxPercent: 21,
      discount: 0,
      listofItems: [],
      listofAdditonalItems:[],
      listofPaymentItems: [],
      notes: '',
      accept: false,
      signature: '',
      company: {},
      user: {},
      error: false,
      timezone: '',
      isMobile: false
    }
  },
  computed: {
    originalSubTotal(){
      let itemsPrices = this.listofItems.reduce(function (total, item) {
        return total + item.price*item.quantity
      }, 0)

      let additionalItemsPrices = this.listofAdditonalItems.reduce(function (total, item) {
        if(item.is_selected){
          total = total + item.price*item.quantity
        }
        return total
      }, 0)

      let total = Number(itemsPrices) + Number(additionalItemsPrices)
      return Number((total).toFixed(2))
    },
    discountTotal(){
      let total = 0
      total = this.originalSubTotal * (this.discount/100)
      return Number((total).toFixed(2))
    },
    discountedTotal(){
      let total = 0
      if(this.discountTotal > 0){
        total = this.originalSubTotal - this.discountTotal
      }
      return total
    },
    taxClasses(){
      let results = calculateEachTaxClassPriceWithAdditional(this.listofItems, this.listofAdditonalItems, this.originalSubTotal, this.discountedTotal, this.isTaxPriceIncluded)
      return results
    },
    subTotal(){
      let itemsPrices = this.listofItems.reduce(function (total, item) {
        return total + item.price*item.quantity
      }, 0)

      let additionalItemsPrices = this.listofAdditonalItems.reduce(function (total, item) {
        if(item.is_selected){
          total = total + item.price*item.quantity
        }
        return total
      }, 0)

      let total = Number(itemsPrices) + Number(additionalItemsPrices)
      total = total - this.discountTotal
      return Number((total).toFixed(2))
    },
    viewSubTotal(){

      let subTotal = this.originalSubTotal - this.discountTotal
      if(this.isTaxEnable && this.isTaxPriceIncluded){
        subTotal = this.originalSubTotal - this.discountTotal

        let value =  parseFloat(subTotal - this.taxTotal)
        return trim2Decimals(value)
      }

      return trim2Decimals(subTotal)
    },
    taxTotal(){
      // let itemsTaxTotal = calculateTaxTotal(this.listofItems, this.isTaxPriceIncluded)
      // let additionalItemsTaxTotal = calculateTaxTotal(this.listofAdditonalItems, this.isTaxPriceIncluded)

      let itemsTaxTotal = calculateTaxTotal(this.listofItems, this.originalSubTotal, this.discountedTotal, this.isTaxPriceIncluded)
      let additionalItemsTaxTotal = calculateTaxTotal(this.listofAdditonalItems, this.originalSubTotal, this.discountedTotal, this.isTaxPriceIncluded)

      let total = Number(itemsTaxTotal)+Number(additionalItemsTaxTotal)

      if(total > 0){
        return Number((total).toFixed(2))
      }

      return 0
    },
    total(){
      let total = 0

      total = this.subTotal
      if(this.isTaxEnable && !this.isTaxPriceIncluded){
        total += Number(this.taxTotal)
      }

       return Number((total).toFixed(2))
    }
  },
  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },
  async mounted() {
    await this.getProposal()

    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })

  },
  methods: {
    checkQuantity(item, $event){
      let value = $event.target.value
      if(value < 0){
        item.quantity = 0
      }
      if(value > 99){
        item.quantity = 99
      }

    },
    onResize () {
      this.isMobile = window.innerWidth < 769
    },
    onPdfProgress($event){
      this.pdfLoaderProgress = $event
    },
    async savePdf(){

      var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
      const data = {
        company: this.company.id,
        proposalId: this.proposal.id,
        taxClasses: this.taxClasses,
        isMobile: this.isMobile,
        viewDiscountedTotal: this.discountTotal,
        subTotal: this.viewSubTotal,
        isSafari: isSafari,
        logoWidth: this.logo.width,
        logoHeight: this.logo.height,
      }

      this.isPdfLoading = true
      await this.$api.get('pdf').proposal(data).then(response => {
        if(!isSafari && !this.isMobile){
          let blob = new Blob([response.data], { type: 'application/pdf' })
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `proposal-${this.proposal.id}.pdf`
          link.click()
        } else {
          window.location.href = response.data
          //window.open(response.data, '_blank').focus()
          //window.location.open = response.data
          window.location.href = response.data
        }

        this.isPdfLoading = false
      })
    },
    finishPdfGenerate($event){
      this.isPdfLoading = false
      document.getElementsByTagName('body')[0].style.overflow = 'visible'
      this.$toasted.global.general_success({
        message : 'Proposal PDF downloaded.'
      })
    },
    calculateUnitPrice(item){
      return item.price*item.quantity
    },
    dateFormatter(value) {
      if(value == null){
        return '-'
      }

      let date = format(value, 'YYYY-MM-DD')
      return date
    },
    triggerMissingError(){
      this.$notify({
        group: 'error',
        duration: 2000,
        title: 'Please fill in the missing information'
      })
    },
    switchView(view){
      this.currentView = view
    },
    async acceptProposal(){
      this.$toasted.clear()
      this.saving = true

      if(!this.accept || !this.signature) {
        this.$toasted.global.general_error({
          message : this.$translate('pleaseSign', this.locale)
        })
        this.saving = false
        return false
      }

      const postData = {
        signature: this.signature,
        items: this.listofItems,
        additionalItems: this.listofAdditonalItems,
        subTotal: this.subTotal,
        taxTotal: this.taxTotal,
        discountTotal: this.discountTotal,
        total: this.total,
      }

      try {
        const { data } = await this.$api.get('proposal').accept(postData, this.token)
        await this.getProposal()

        this.$modal.show('successAccepted')
        setTimeout(() =>  this.$modal.hide('successAccepted'), 5000)

        this.listofAdditonalItems.forEach(item => {
          item.is_quantity_selectable = 0
        })

      } catch(e){
        this.saving = false
        this.$toasted.global.api_error(e.response.data)
      }
    },
    async getProposal(id, type = null){

      let that = this

      try {

        let target = this.token
        if(!this.token) {
          target = this.id
          this.viewType = 'edit'
        }

        let result = {}
        result  = await this.$api.get('proposal').view(target, this.viewType)

        const {
            proposal,
            companySettings,
            logo
          } = result.data

        this.logo.path = logo
        if(this.logo.path){
          let image = new Image()
          image.src = this.logo.path
          image.onload = function () {
            that.logo.width = this.width
            that.logo.height = this.height
          }
        }

        this.currency = proposal.currency
        this.timezone = companySettings.timezone
        this.numberPrefix = companySettings.numberPrefix
        this.paymentInfo = companySettings.paymentInfo

        this.proposal = proposal
        this.notes = proposal.notes
        this.contact = proposal.project.contact
        this.user = proposal.company.users[0]
        this.company = proposal.company
        this.locale = proposal.project.locale

        this.referenceNumber = proposal.reference_number
        this.subject = proposal.subject
        this.issuedAt = proposal.issued_at
        this.validUntil = proposal.valid_until
        this.isTaxEnable = proposal.is_tax_enable
        this.isTaxPriceIncluded = proposal.is_tax_price_included
        this.isContractIncluded = proposal.has_contract
        this.isInvoiceGenerated = proposal.has_invoice
        this.isPaymentScheduleActive = proposal.has_payment_schedules
        this.taxPercent = proposal.tax
        this.discount = proposal.discount
        this.signature = proposal.signature

        let metaTitle = ''
        if(this.subject == '' || this.subject == null){
          metaTitle = `: ${this.name}`
        } else if(this.name == '' || this.name == null){
          metaTitle = `: ${this.subject}`
        }

        if(!this.token) {
          this.metaTitle = `Preview Proposal${metaTitle}`
        } else {
          this.metaTitle = `Proposal${metaTitle}`
          if(proposal.project.id != this.$route.params.projectId) {
            window.location = 'https://octoa.com'
          }
        }

        this.listofItems = []
        this.listofAdditonalItems = []
        this.listofPaymentItems = []

        let that = this
        proposal.items.forEach(function(item) {

          let quantity = String(item.quantity)
          if(quantity.includes(".00")){
            item.quantity = parseInt(item.quantity)
          }

          if(item.is_optional){

            that.listofAdditonalItems.push(item)
          } else {
            that.listofItems.push(item)
          }
        })

        if(proposal.payment_schedules){
          proposal.payment_schedules.forEach(function(item) {
            that.listofPaymentItems.push(item)
          })
        }

        if(proposal.has_contract){
          this.contract = proposal.contract
        }

        if(proposal.accepted_at){
          this.listofAdditonalItems.forEach(item => {
            item.is_quantity_selectable = 0
          })
        }

        this.loading = false

      } catch(e){
        this.loading = false
        this.metaTitle = 'Proposal not found'
        this.error = true
        this.$router.push('/')
      }
    },
    async copyShareLink(){
      if(this.proposal.state == 'draft'){
        const can = await this.$alert.confirm({
          title: 'Proposal has not been sent out',
          text: 'In order to copy a share link, we will mark this proposal as sent.'
        })

        if (can) {
          try {
            await this.$api.get('proposal').updateState(this.proposal.id, 'sent')
              const link = `${config.frontBaseUrl}/view/${this.proposal.project.id}/proposal/${this.proposal.token}`
              let dummy = document.createElement('textarea')
              document.body.appendChild(dummy)
              dummy.value = link
              dummy.select()
              document.execCommand('copy')
              document.body.removeChild(dummy)

              this.$toasted.global.general_success({
                message : 'Share link has been copied to your clipboard.'
              })

              setTimeout(() => {
                window.location.reload()
              }, 500)

          } catch(e){
            this.$toasted.global.api_error(e)
            logException(e)
          }
        }
      } else {
        const link = `${config.frontBaseUrl}/view/${this.proposal.project.id}/proposal/${this.proposal.token}`
        let dummy = document.createElement('textarea')
        document.body.appendChild(dummy)
        dummy.value = link
        dummy.select()
        document.execCommand('copy')
        document.body.removeChild(dummy)

        this.$toasted.global.general_success({
          message : 'Share link has been copied to your clipboard.'
        })
      }
    },
    async deleteItem(){

      const canDelete = await this.$alert.confirm({
        title: 'Are you sure you want to delete this proposal?',
        text: 'If you delete this, sent item will no longer accessible by the client.',
      })

      if (canDelete) {
        try {
          try {
            const postData = {
              proposalId: this.id,
            }
            await this.$api.get('proposal').delete(postData)
            this.$router.push(`/projects/${this.$route.params.id}`)

          } catch(e){
            this.$toasted.global.api_error(e)
            logException(e)
          }

        } catch(e){
          logException(e)
        }
      }
    },
    async markAsSent(){
      const can = await this.$alert.confirm({
        title: 'Are you sure you want to mark this proposal as sent?',
      })

      if (can) {
        try {
          await this.$api.get('proposal').updateState(this.id, 'sent')
          setTimeout(() => {
            window.location.reload()
          }, 500)

        } catch(e){
          this.$toasted.global.api_error(e)
          logException(e)
        }
      }
    },
    async markAsUnsent(){
      const can = await this.$alert.confirm({
        title: 'Are you sure you want to mark this proposal as draft?',
      })

      if (can) {
        try {
          await this.$api.get('proposal').updateState(this.id, 'draft')
          setTimeout(() => {
            window.location.reload()
          }, 500)

        } catch(e){
          this.$toasted.global.api_error(e)
          logException(e)
        }
      }
    },
    edit(){
      this.$router.push(`/projects/${this.proposal.project.id}/proposal/${this.proposal.id}`)
    },
  }
}
</script>
